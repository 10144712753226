<template>
	<!--	<el-dialog-->
	<!--		:title=" '问题详情'"-->
	<!--		:close-on-click-modal="false"-->
	<!--		:visible.sync="visible"-->
	<!--		width="1200px">-->
	<div class="mod-config">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true">
			<el-card style="margin-top:10px; height: 100%;">
				<div class="elCardTitle">意见及问题信息</div>
				<el-row>
					<el-col :span="6">
						<el-form-item label="标的物" class="item">
							{{ dataForm.subjectName }}
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="问题来源" class="item">
							{{ dataForm.probSourceName }}
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="专业" class="item">
							{{ dataForm.majorName }}
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<el-form-item label="范畴" class="item">
							{{ dataForm.categoryName }}
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="状态" class="item">
							<span v-if="dataForm.stateFlg == 0">未封闭</span>
							<span v-if="dataForm.stateFlg == 1">已封闭</span>
							<span v-if="dataForm.stateFlg == 2">取消</span>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="重要程度" class="item">
							<span v-if="dataForm.iptFlg == 0">普通</span>
							<span v-if="dataForm.iptFlg == 1" style="color: #e7b243;">重要</span>
							<span v-if="dataForm.iptFlg == 2" style="color: #e70013;">非常重要</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="6">
						<el-form-item label="提出人" class="item">
							{{ dataForm.poseMan }}
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="提出时间" class="item">
							{{ dataForm.poseTime }}
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<div style="font-size: 14px; color: #00428e; font-weight: bold;">巡视/报验内容</div>
					</el-col>
					<el-col :span="24">
						<el-form-item label=" ">
							{{ dataForm.probContext }}
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<div style="font-size: 14px; color: #00428e; font-weight: bold;">问题描述及整改意见</div>
					</el-col>
					<el-col :span="24">
						<el-form-item label=" ">
							{{ dataForm.sutContent }}
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="问题照片" class="item">
							<div class="addImgBox" v-for="(item, index) in dataForm.photoList">
								<el-image style="width: 100%; height: 100%" :src="item.fileUri" fit="cover"></el-image>
							</div>
							<div v-if="!dataForm.photoList || dataForm.photoList.length === 0">
								<span style="color: #e7b243;">暂无问题照片</span>
							</div>
						</el-form-item>
					</el-col>
				</el-row>
			</el-card>
			<el-card style="margin-top:10px; height: 100%;" v-if="dataForm.stateFlg == 1">
				<div class="elCardTitle">整改结果</div>
				<el-col :span="8">
					<el-form-item label="封闭人" class="item">
						{{ dataForm.closeMan }}
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="封闭时间" class="item">
						{{ dataForm.closeTime | formatDate('yyyy-MM-dd') }}
					</el-form-item>
				</el-col>
				<el-col :span="8">
				</el-col>
				<el-col :span="24">
					<el-form-item label="封闭时相关文件" class="item">
						<div class="addFileBox" v-for="(item, index) in dataForm.fileList">
							<div>
								<span
									style="display: inline-block; width: 200px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; vertical-align: top;"
									@click="lookFile(item.fileUri)">
									{{ item.fileName }}
								</span>
							</div>
						</div>
						<div v-if="!dataForm.fileList || dataForm.fileList.length === 0">
							<span style="color: #e7b243;">暂无封闭相关文件</span>
						</div>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="封闭时相关照片" class="item">
						<div class="addImgBox" v-for="(item, index) in dataForm.afterPhotoList">
							<el-image style="width: 100%; height: 100%" :src="item.fileUri" fit="cover"></el-image>
						</div>
						<div v-if="!dataForm.afterPhotoList || dataForm.afterPhotoList.length === 0">
							<span style="color: #e7b243;">暂无封闭相关照片</span>
						</div>
					</el-form-item>
				</el-col>
			</el-card>
			<el-card style="margin-top:10px; height: 100%;" v-if="dataForm.stateFlg == 2">
				<div class="elCardTitle">取消信息</div>
				<el-col :span="8">
					<el-form-item label="取消人" label-width="80">
						{{ dataForm.cancelMan }}
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="取消时间" label-width="80">
						{{ dataForm.cancelTime }}
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="取消原因" label-width="80">
						{{ dataForm.cancelRsn }}
					</el-form-item>
				</el-col>
			</el-card>
			<el-card style="margin-top:10px; height: 100%;">
				<div class="elCardTitle">跟踪记录</div>
				<el-table class="dataListTable" :data="dataList" header-align="center"
					style="width: 100%; margin-top: 20px;" header-cell-class-name="stepPointTHeader">
					<el-table-column type="index" width="60" align="center">
					</el-table-column>
					<el-table-column prop="problemContent" label="跟踪内容" align="left" header-align="center">
					</el-table-column>
					<el-table-column prop="registerTime" label="登记时间" align="center" width="100" header-align="center">
					</el-table-column>
					<el-table-column prop="registerMan" label="登记人 " width="100" align="center" header-align="center">
					</el-table-column>
					<el-table-column prop="problemRemark" label="备注 " width="250" align="center">
					</el-table-column>
				</el-table>
			</el-card>
			<el-row class="formBtnGroup">
				<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="backPage()">返回</el-button>
			</el-row>
		</el-form>
	</div>
	<!--	</el-dialog>-->
</template>

<script>
import addImg from "@/assets/images/addImg.png"
import $common from "@/utils/common.js"
export default {
	name: "problemInfo",
	data() {
		return {
			addImg: addImg,
			id: "",
			visible: true,
			form: {},
			action: "",
			problemTypeList: [],
			categoryList: [],
			// subjectList:[],
			majorList: [],
			dataList: [],
			dataForm: {
				workNo: $common.getItem("workNo"),
				probId: "",
				probSource: "",
				poseMan: "",
				poseTime: "",
				category: "",
				subjectNo: "",
				major: "",
				photoList: [],
				implMan: "",
				implTime: "",
				sutContent: ""
			},
			dataRule: {

			},
		};
	},
	components: {

	},
	activated() {

	},
	mounted() {
		this.dataForm.probId = this.$route.query.probId;
		this.getDetail();
		this.getMajorList()
	},
	filters: {
		formatDate: function (value, args) {
			if (!value) {
				return ''
			}
			var dt = new Date(value);
			if (args == 'yyyy-M-d') {// yyyy-M-d
				let year = dt.getFullYear();
				let month = dt.getMonth() + 1;
				let date = dt.getDate();
				return `${year}-${month}-${date}`;
			} else if (args == 'yyyy-M-d H:m:s') {// yyyy-M-d H:m:s
				let year = dt.getFullYear();
				let month = dt.getMonth() + 1;
				let date = dt.getDate();
				let hour = dt.getHours();
				let minute = dt.getMinutes();
				let second = dt.getSeconds();
				return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
			} else if (args == 'yyyy-MM-dd') {// yyyy-MM-dd
				let year = dt.getFullYear();
				let month = (dt.getMonth() + 1).toString().padStart(2, '0');
				let date = dt.getDate().toString().padStart(2, '0');
				return `${year}-${month}-${date}`;
			} else {// yyyy-MM-dd HH:mm:ss
				let year = dt.getFullYear();
				let month = (dt.getMonth() + 1).toString().padStart(2, '0');
				let date = dt.getDate().toString().padStart(2, '0');
				let hour = dt.getHours().toString().padStart(2, '0');
				let minute = dt.getMinutes().toString().padStart(2, '0');
				let second = dt.getSeconds().toString().padStart(2, '0');
				return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
			}
		}
	},
	methods: {
		getInspection() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/inspectioninfo/info/" + this.dataForm.testId,
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataForm.photoList = []
					data.body.photo.forEach((item) => {
						let item1 = {
							"fileUri": item.photoPathUri
						}
						this.dataForm.photoList.push(item1)
					})

					// if (!this.dataForm.inspectionMan){
					//   this.dataForm.inspectionMan = $common.getItem("userName");
					// }
				}
			});
		},
		getRecordLook() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/testrecord/info/" + this.dataForm.testId,
				method: "get",
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					// this.dataForm = data.body;
					this.dataForm.photoList = []
					data.body.list.forEach((item) => {
						let item1 = {
							"fileUri": item.photoPathUri
						}
						this.dataForm.photoList.push(item1)
					})
				}
			});
		},
		getTrackRecord() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/problemtrackrecord/listByProbId?probId=" + this.dataForm.probId,
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataList = data.body
				}
			});
		},
		lookFile(url) {
			window.open(url);
		},
		backPage() {
			this.$router.go(-1);
		},
		// init(id) {
		// 	this.getProblemTypeList();
		// 	this.getCategoryList();
		// 	this.getMajorBySubject();
		// 	this.dataForm = {
		// 		workNo: $common.getItem("workNo"),
		// 		probId:"",
		// 		probSource:"",
		// 		poseMan:"",
		// 		poseTime:"",
		// 		category:"",
		// 		subjectNo:$common.getItem("subjectNo"),
		// 		major:"",
		// 		probContext:"",
		// 		photoList:[],
		// 		implMan:"",
		// 		implTime:"",
		// 		sutContent:""
		// 	}
		// 	this.dataForm.probId = id?id:0;
		// 	if(id){
		// 		this.getDetail();
		// 	}
		// 	this.$nextTick(() => {
		// 		this.visible = true;
		// 	})
		//
		// },
		getSutConclusion() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getSutConclusion",
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.sutConclusionList = data.body;
				}
			});
		},
		getSutConclusionName(value) {
			let name = '';
			if (!this.sutConclusionList) {
				this.$http({
					url: this.$store.state.httpUrl + "/common/getSutConclusion",
					method: "get",
					params: {},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.sutConclusionList = data.body;
						this.sutConclusionList.forEach(function (item) {
							if (item.displayValue === value) {
								name = item.displayName;
							}
						});
					}
				});
			} else {
				this.sutConclusionList.forEach(function (item) {
					if (item.displayValue === value) {
						name = item.displayName;
					}
				});
			}
			return name;
		},
		getProblemTypeList() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getProblemType",
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.problemTypeList = data.body;
				}
			});
		},
		getCategoryList() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getCategoryList",
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.categoryList = data.body;
				}
			});
		},
		getMajorList() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getMajorByWorkNo/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.majorList = data.body;
				}
			});
		},
		getDetail() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/probleminfo/info/" + this.dataForm.probId,
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataForm = data.body
					this.getProblemTypeList();
					this.getCategoryList();
					this.getSutConclusion();
					this.getTrackRecord();
					if (this.dataForm.testId) {
						if (this.dataForm.probSource === '0') {
							this.getInspection()
						} else if (this.dataForm.probSource === '1') {
							this.getRecordLook()
						}
					}
				}
			});
		},
		delImg(index) {
			let _photoList = this.dataForm.photoList;
			_photoList.splice(index, 1);
			this.dataForm.photoList = _photoList;
		},
		uploadImg(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadImg.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/10/0",
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data
				if (res.resultCode == 200) {
					this.dataForm.photoList.push({
						photoName: res.body.fileName,
						photoPath: res.body.fileSavePath,
						fileUri: res.body.fileUri
					});
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		dataFormSubmit() {
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					let _url = "/business/probleminfo/save";
					if (this.dataForm.probId && this.dataForm.probId != 0) {
						_url = "/business/probleminfo/update";
					}
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData(this.dataForm)
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$emit("refreshDataList");
								}
							});
						} else {
							this.$message.error(data.msg);
						}
					});
				}
			});
		}
	}
};
</script>

<style lang="scss">
.elCardTitle {
	font-size: 18px;
	font-weight: bold;
	color: #00428e;
	margin-bottom: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid #00428e;
}

.selItemInput.descAreaLg {
	width: 1030px !important;
}

.addImgBox {
	width: 136px;
	height: 136px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
	position: relative;
}

.item .el-form-item__label {
	color: #00428e !important;
	margin-right: 10px !important;
	;
	font-weight: bold !important;
	;
}
</style>
